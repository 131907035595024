export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry,"])},
        "errorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong"])}
      },
      "ru": {
        "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Извините,"])},
        "errorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["что-то пошло не так"])}
      },
      "hy": {
        "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ներեցեք,"])},
        "errorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ինչ-որ բան այնպես չէ"])}
      },
      "kk": {
        "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кешіріңіз,"])},
        "errorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бір қателік кетті"])}
      }
    }
  })
}
