<template>
  <div class="error">
    <div class="error__content">
      <div class="message">
        <span class="message__title">{{ t('errorTitle') }}</span>
        <span class="message__text">{{ t('errorText') }}</span>
        <span v-if="error" class="message__error">{{ error }}</span>
      </div>

      <div :class="['img']">
        <template v-if="theme?.images?.error">
          <component :is="theme.images.error" />
        </template>
        <template v-else>
          <error-img class="img-base" />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useTheme } from '@/themes/hooks';
import ErrorImg from '@/components/images/Error.vue';

interface Props {
  error?: string;
}

defineProps<Props>();

const { t } = useI18n();
const { theme } = useTheme();
</script>

<style scoped lang="less">
.error {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: var(--question-item-bg-color);
  padding-top: 70px;
  min-height: calc(100vh - var(--progress-bg-width) - var(--header-height));

  &__content {
    display: flex;
    align-items: center;

    .message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: var(--primary-color);
      font-weight: var(--error-text-font-weight);

      &__title {
        font-size: 59px;
      }

      &__text {
        font-size: 41px;
      }

      &__error {
        font-weight: 400;
        font-size: 14px;
      }
    }

    .img {
      display: flex;

      &-base {
        color: var(--primary-color);
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "errorTitle": "Sorry,",
    "errorText": "something went wrong"
  },
  "ru": {
    "errorTitle": "Извините,",
    "errorText": "что-то пошло не так"
  },
  "hy": {
    "errorTitle": "ներեցեք,",
    "errorText": "ինչ-որ բան այնպես չէ"
  },
  "kk": {
    "errorTitle": "Кешіріңіз,",
    "errorText": "бір қателік кетті"
  }
}
</i18n>
