<template>
  <div :class="['complete']">
    <div :class="['complete__content']">
      <div :class="['complete__content__header']">
        <span :class="['complete__content__header__title']">
          {{ t(`${theme?.translate?.complete?.['Thank you!'] ?? 'Thank you!'}`) }}
        </span>
        <span :class="['complete__content__header__description']">
          {{ t(`${theme?.translate?.complete?.['You helped us a lot'] ?? 'You helped us a lot'}`) }}
        </span>
      </div>

      <div :class="['complete__content__img']">
        <template v-if="theme?.images?.complete">
          <component :is="theme.images.complete" />
        </template>
        <template v-else>
          <complete-img />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CompleteImg from '@/components/images/Complete.vue';
import { useTheme } from '@/themes/hooks';
import { useI18n } from 'vue-i18n';

const { theme } = useTheme();
const { t } = useI18n();
</script>

<style scoped lang="less">
@import '@/styles/breakpoints';

.complete {
  display: flex;
  align-items: center;
  background: var(--question-item-bg-color);
  min-height: calc(100vh - var(--progress-bg-width) - var(--header-height));

  &__content {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: var(--header-padding);
    max-width: var(--question-item-width-1920);
    width: 100%;
    justify-content: space-between;
    gap: 20px;

    @media @size375, @size768 {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      max-width: 707px;
    }

    &__header {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 500px;
      width: max-content;

      &__title {
        font-size: 41px;
        font-weight: var(--complete-title-font-weight);
        color: var(--complete-title-color);
        width: max-content;
        max-width: 100%;
      }

      &__description {
        font-size: 23px;
        width: max-content;
        max-width: 100%;
      }
    }

    &__img {
      display: flex;

      & > svg {
        width: 100%;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "Thank you!": "Thank you!",
    "You helped us a lot": "You helped us a lot",
    "Thank you for participating in the survey!": "Thank you for participating in the survey!",
    "Your feedback is very important to us.": "Your feedback is very important to us."
  },
  "ru": {
    "Thank you!": "Спасибо!",
    "You helped us a lot": "Вы нам очень помогли",
    "Thank you for participating in the survey!": "Благодарим за участие в опросе!",
    "Your feedback is very important to us.": "Ваше мнение очень важно для нас."
  },
  "hy": {
    "Thank you!": "Շնորհակալություն",
    "You helped us a lot": "Դուք մեզ շատ օգնեցիք"
  },
  "kk": {
    "Thank you!": "рақмет",
    "You helped us a lot": "Сіздің үлкен көмегіңіз тиді"
  }
}
</i18n>
